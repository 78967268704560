import MediaQuery from 'react-responsive';

export const breakpoints = {
  screenXs: 480,
  screenXsMax: 767,
  screenSm: 768,
  screenSmMax: 991,
  screenMd: 992,
  screenMdMax: 1199,
  screenLg: 1200,
  screenLgMax: 1249,
  screenXl: 1250
};

export const matchMedia = {
  screenXs() {
    return window.matchMedia(`(max-width: ${breakpoints.screenXs}px)`).matches;
  },

  screenSm() {
    return window.matchMedia(`(max-width: ${breakpoints.screenSm}px)`).matches;
  },

  screenMd() {
    return window.matchMedia(`(max-width: ${breakpoints.screenMd}px)`).matches;
  },

  screenLg() {
    return window.matchMedia(`(max-width: ${breakpoints.screenLg}px)`).matches;
  },

  screenXl() {
    return window.matchMedia(`(max-width: ${breakpoints.screenXl}px)`).matches;
  }
};

export default MediaQuery;
