import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery, { breakpoints } from 'utils/media-query';

import ActivityIcon from 'components/ActivityIcon';

import styles from './styles.scss';

const DayBlock = (isCompletable, icon, day, today, date) => (
  <>
    <div
      className={`${isCompletable && icon ? styles.active : ''} 
        ${today ? styles.today : ''} 
        ${styles.day} 
        ${icon ? styles.icon : ''}`}
    >
      <MediaQuery maxWidth={breakpoints.screenXs}>
        {icon ? (
          <ActivityIcon size="small" type={icon} />
        ) : (
          <time dateTime={date} className="text-footnote">
            {day}
          </time>
        )}
      </MediaQuery>

      <MediaQuery minWidth={breakpoints.screenXs}>
        {icon ? (
          <ActivityIcon size="small" type={icon} />
        ) : (
          <time dateTime={date} className="text-callout">
            {day}
          </time>
        )}
      </MediaQuery>
    </div>
    {today && <div className={styles.triangle} />}
  </>
);

const DayBlockWithLink = (
  isCompletable,
  icon,
  day,
  activityPath,
  today,
  date
) => (
  <>
    <a href={activityPath}>{DayBlock(isCompletable, icon, day, today, date)}</a>
  </>
);

const Day = ({ isCompletable, icon, day, activityPath, today, date }) => (
  <div className={styles.container}>
    {activityPath
      ? DayBlockWithLink(isCompletable, icon, day, activityPath, today, date)
      : DayBlock(isCompletable, icon, day, today, date)}
  </div>
);

Day.propTypes = {
  isCompletable: PropTypes.bool.isRequired,
  icon: PropTypes.string,
  day: PropTypes.number.isRequired,
  activityPath: PropTypes.string,
  today: PropTypes.bool.isRequired,
  date: PropTypes.string.isRequired
};

export default Day;
