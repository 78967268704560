import React from 'react';
import PropTypes from 'prop-types';

import ActivityIcon from 'components/ActivityIcon';

import styles from './styles.scss';

const QualifyingActivities = ({ activities, id }) => (
  <ul id={id} className={styles.activities}>
    {activities.map((activity) => (
      <li key={activity.text} className={styles.activity}>
        <div className={styles.activityIconWrapper}>
          <ActivityIcon size="xsmall" type={activity.activityType} />
        </div>
        {activity.text}
      </li>
    ))}
  </ul>
);

QualifyingActivities.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      activityType: PropTypes.string.isRequired
    }).isRequired
  ).isRequired,
  id: PropTypes.string.isRequired
};

export default QualifyingActivities;
