//
// This file contains imports, such as React and other 3rd party libraries,
// that do not change frequently.
//
// adding random string to force recompile:
// A998D3152224818D225A880A9B627F1FB4484DD7E408EACA1B243326ED4AA01B
// General polifyll's
import 'core-js/es6/array';
// React polifyll's
import 'core-js/es6/promise'; // for IE 11 and below
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/modules/es6.object.assign';
import 'core-js/es6/string';
import 'raf/polyfill';
// React core
import 'react';
import 'react-dom';
/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
// Utils
import ReactComponentMounter from './utils/react-component-mounter';
import Microfrontend from './apps/Microfrontend';

ReactComponentMounter.register({
  Microfrontend
});
