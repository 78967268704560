import SportsRunNormalXsmall from '@strava/icons/SportsRunNormalXsmall';
import SportsRunNormalSmall from '@strava/icons/SportsRunNormalSmall';
import SportsRunNormalMedium from '@strava/icons/SportsRunNormalMedium';
import SportsRunNormalLarge from '@strava/icons/SportsRunNormalLarge';
import SportsBikeNormalXsmall from '@strava/icons/SportsBikeNormalXsmall';
import SportsBikeNormalSmall from '@strava/icons/SportsBikeNormalSmall';
import SportsBikeNormalMedium from '@strava/icons/SportsBikeNormalMedium';
import SportsBikeNormalLarge from '@strava/icons/SportsBikeNormalLarge';
import SportsOtherNormalXsmall from '@strava/icons/SportsOtherNormalXsmall';
import SportsOtherNormalSmall from '@strava/icons/SportsOtherNormalSmall';
import SportsOtherNormalMedium from '@strava/icons/SportsOtherNormalMedium';
import SportsOtherNormalLarge from '@strava/icons/SportsOtherNormalLarge';
import SportsMultiNormalXsmall from '@strava/icons/SportsMultiNormalXsmall';
import SportsMultiNormalSmall from '@strava/icons/SportsMultiNormalSmall';
import SportsMultiNormalMedium from '@strava/icons/SportsMultiNormalMedium';
import SportsMultiNormalLarge from '@strava/icons/SportsMultiNormalLarge';
import SportsEBikeRideNormalXsmall from '@strava/icons/SportsEBikeRideNormalXsmall';
import SportsEBikeRideNormalSmall from '@strava/icons/SportsEBikeRideNormalSmall';
import SportsEBikeRideNormalMedium from '@strava/icons/SportsEBikeRideNormalMedium';
import SportsEBikeRideNormalLarge from '@strava/icons/SportsEBikeRideNormalLarge';
import SportsStandUpPaddlingNormalXsmall from '@strava/icons/SportsStandUpPaddlingNormalXsmall';
import SportsStandUpPaddlingNormalSmall from '@strava/icons/SportsStandUpPaddlingNormalSmall';
import SportsStandUpPaddlingNormalMedium from '@strava/icons/SportsStandUpPaddlingNormalMedium';
import SportsStandUpPaddlingNormalLarge from '@strava/icons/SportsStandUpPaddlingNormalLarge';
import SportsHandcycleNormalXsmall from '@strava/icons/SportsHandcycleNormalXsmall';
import SportsHandcycleNormalSmall from '@strava/icons/SportsHandcycleNormalSmall';
import SportsHandcycleNormalMedium from '@strava/icons/SportsHandcycleNormalMedium';
import SportsHandcycleNormalLarge from '@strava/icons/SportsHandcycleNormalLarge';
import SportsHikeNormalXsmall from '@strava/icons/SportsHikeNormalXsmall';
import SportsHikeNormalSmall from '@strava/icons/SportsHikeNormalSmall';
import SportsHikeNormalMedium from '@strava/icons/SportsHikeNormalMedium';
import SportsHikeNormalLarge from '@strava/icons/SportsHikeNormalLarge';
import SportsIceSkateNormalXsmall from '@strava/icons/SportsIceSkateNormalXsmall';
import SportsIceSkateNormalSmall from '@strava/icons/SportsIceSkateNormalSmall';
import SportsIceSkateNormalMedium from '@strava/icons/SportsIceSkateNormalMedium';
import SportsIceSkateNormalLarge from '@strava/icons/SportsIceSkateNormalLarge';
import SportsInlineSkateNormalXsmall from '@strava/icons/SportsInlineSkateNormalXsmall';
import SportsInlineSkateNormalSmall from '@strava/icons/SportsInlineSkateNormalSmall';
import SportsInlineSkateNormalMedium from '@strava/icons/SportsInlineSkateNormalMedium';
import SportsInlineSkateNormalLarge from '@strava/icons/SportsInlineSkateNormalLarge';
import SportsKayakingNormalXsmall from '@strava/icons/SportsKayakingNormalXsmall';
import SportsKayakingNormalSmall from '@strava/icons/SportsKayakingNormalSmall';
import SportsKayakingNormalMedium from '@strava/icons/SportsKayakingNormalMedium';
import SportsKayakingNormalLarge from '@strava/icons/SportsKayakingNormalLarge';
import SportsKitesurfNormalXsmall from '@strava/icons/SportsKitesurfNormalXsmall';
import SportsKitesurfNormalSmall from '@strava/icons/SportsKitesurfNormalSmall';
import SportsKitesurfNormalMedium from '@strava/icons/SportsKitesurfNormalMedium';
import SportsKitesurfNormalLarge from '@strava/icons/SportsKitesurfNormalLarge';
import SportsRockClimbingNormalXsmall from '@strava/icons/SportsRockClimbingNormalXsmall';
import SportsRockClimbingNormalSmall from '@strava/icons/SportsRockClimbingNormalSmall';
import SportsRockClimbingNormalMedium from '@strava/icons/SportsRockClimbingNormalMedium';
import SportsRockClimbingNormalLarge from '@strava/icons/SportsRockClimbingNormalLarge';
import SportsRowingNormalXsmall from '@strava/icons/SportsRowingNormalXsmall';
import SportsRowingNormalSmall from '@strava/icons/SportsRowingNormalSmall';
import SportsRowingNormalMedium from '@strava/icons/SportsRowingNormalMedium';
import SportsRowingNormalLarge from '@strava/icons/SportsRowingNormalLarge';
import SportsSkiNormalXsmall from '@strava/icons/SportsSkiNormalXsmall';
import SportsSkiNormalSmall from '@strava/icons/SportsSkiNormalSmall';
import SportsSkiNormalMedium from '@strava/icons/SportsSkiNormalMedium';
import SportsSkiNormalLarge from '@strava/icons/SportsSkiNormalLarge';
import SportsSnowboardNormalXsmall from '@strava/icons/SportsSnowboardNormalXsmall';
import SportsSnowboardNormalSmall from '@strava/icons/SportsSnowboardNormalSmall';
import SportsSnowboardNormalMedium from '@strava/icons/SportsSnowboardNormalMedium';
import SportsSnowboardNormalLarge from '@strava/icons/SportsSnowboardNormalLarge';
import SportsSnowNormalXsmall from '@strava/icons/SportsSnowNormalXsmall';
import SportsSnowNormalSmall from '@strava/icons/SportsSnowNormalSmall';
import SportsSnowNormalMedium from '@strava/icons/SportsSnowNormalMedium';
import SportsSnowNormalLarge from '@strava/icons/SportsSnowNormalLarge';
import SportsSnowshoeNormalXsmall from '@strava/icons/SportsSnowshoeNormalXsmall';
import SportsSnowshoeNormalSmall from '@strava/icons/SportsSnowshoeNormalSmall';
import SportsSnowshoeNormalMedium from '@strava/icons/SportsSnowshoeNormalMedium';
import SportsSnowshoeNormalLarge from '@strava/icons/SportsSnowshoeNormalLarge';
import SportsSurfingNormalXsmall from '@strava/icons/SportsSurfingNormalXsmall';
import SportsSurfingNormalSmall from '@strava/icons/SportsSurfingNormalSmall';
import SportsSurfingNormalMedium from '@strava/icons/SportsSurfingNormalMedium';
import SportsSurfingNormalLarge from '@strava/icons/SportsSurfingNormalLarge';
import SportsTriathlonNormalXsmall from '@strava/icons/SportsTriathlonNormalXsmall';
import SportsTriathlonNormalSmall from '@strava/icons/SportsTriathlonNormalSmall';
import SportsTriathlonNormalMedium from '@strava/icons/SportsTriathlonNormalMedium';
import SportsTriathlonNormalLarge from '@strava/icons/SportsTriathlonNormalLarge';
import SportsVelomobileNormalXsmall from '@strava/icons/SportsVelomobileNormalXsmall';
import SportsVelomobileNormalSmall from '@strava/icons/SportsVelomobileNormalSmall';
import SportsVelomobileNormalMedium from '@strava/icons/SportsVelomobileNormalMedium';
import SportsVelomobileNormalLarge from '@strava/icons/SportsVelomobileNormalLarge';
import SportsWalkNormalXsmall from '@strava/icons/SportsWalkNormalXsmall';
import SportsWalkNormalSmall from '@strava/icons/SportsWalkNormalSmall';
import SportsWalkNormalMedium from '@strava/icons/SportsWalkNormalMedium';
import SportsWalkNormalLarge from '@strava/icons/SportsWalkNormalLarge';
import SportsWaterNormalXsmall from '@strava/icons/SportsWaterNormalXsmall';
import SportsWaterNormalSmall from '@strava/icons/SportsWaterNormalSmall';
import SportsWaterNormalMedium from '@strava/icons/SportsWaterNormalMedium';
import SportsWaterNormalLarge from '@strava/icons/SportsWaterNormalLarge';
import SportsWeightTrainingNormalXsmall from '@strava/icons/SportsWeightTrainingNormalXsmall';
import SportsWeightTrainingNormalSmall from '@strava/icons/SportsWeightTrainingNormalSmall';
import SportsWeightTrainingNormalMedium from '@strava/icons/SportsWeightTrainingNormalMedium';
import SportsWeightTrainingNormalLarge from '@strava/icons/SportsWeightTrainingNormalLarge';
import SportsWheelchairNormalXsmall from '@strava/icons/SportsWheelchairNormalXsmall';
import SportsWheelchairNormalSmall from '@strava/icons/SportsWheelchairNormalSmall';
import SportsWheelchairNormalMedium from '@strava/icons/SportsWheelchairNormalMedium';
import SportsWheelchairNormalLarge from '@strava/icons/SportsWheelchairNormalLarge';
import SportsWindsurfNormalXsmall from '@strava/icons/SportsWindsurfNormalXsmall';
import SportsWindsurfNormalSmall from '@strava/icons/SportsWindsurfNormalSmall';
import SportsWindsurfNormalMedium from '@strava/icons/SportsWindsurfNormalMedium';
import SportsWindsurfNormalLarge from '@strava/icons/SportsWindsurfNormalLarge';
import SportsYogaNormalXsmall from '@strava/icons/SportsYogaNormalXsmall';
import SportsYogaNormalSmall from '@strava/icons/SportsYogaNormalSmall';
import SportsYogaNormalMedium from '@strava/icons/SportsYogaNormalMedium';
import SportsYogaNormalLarge from '@strava/icons/SportsYogaNormalLarge';
import ActionsCancelNormalXsmall from '@strava/icons/ActionsCancelNormalXsmall';
import ActionsCancelNormalSmall from '@strava/icons/ActionsCancelNormalSmall';
import ActionsCancelNormalMedium from '@strava/icons/ActionsCancelNormalMedium';
import ActionsCancelNormalLarge from '@strava/icons/ActionsCancelNormalLarge';
import NavigationChallengeTotalActivityNormalSmall from '@strava/icons/NavigationChallengeTotalActivityNormalSmall';
import NavigationChallengeLongestActivityNormalSmall from '@strava/icons/NavigationChallengeLongestActivityNormalSmall';
import NavigationChallengeFastestActivityNormalSmall from '@strava/icons/NavigationChallengeFastestActivityNormalSmall';
import NavigationChallengeGroupGoalNormalSmall from '@strava/icons/NavigationChallengeGroupGoalNormalSmall';
import AchievementsBadgeNormalXsmall from '@strava/icons/AchievementsBadgeNormalXsmall';
import AchievementsBadgeNormalSmall from '@strava/icons/AchievementsBadgeNormalSmall';
import AchievementsBadgeNormalMedium from '@strava/icons/AchievementsBadgeNormalMedium';
import AchievementsBadgeNormalLarge from '@strava/icons/AchievementsBadgeNormalLarge';
import { capitalize, split } from 'lodash-es';
import React from 'react';

export default function getIcon(iconName) {
  const icon = split(iconName, '_')
    .map((s) => capitalize(s))
    .join('');

  switch (icon) {
    case 'SportsRunNormalXsmall':
      return <SportsRunNormalXsmall />;
    case 'SportsRunNormalSmall':
      return <SportsRunNormalSmall />;
    case 'SportsRunNormalMedium':
      return <SportsRunNormalMedium />;
    case 'SportsRunNormalLarge':
      return <SportsRunNormalLarge />;
    case 'SportsBikeNormalXsmall':
      return <SportsBikeNormalXsmall />;
    case 'SportsBikeNormalSmall':
      return <SportsBikeNormalSmall />;
    case 'SportsBikeNormalMedium':
      return <SportsBikeNormalMedium />;
    case 'SportsBikeNormalLarge':
      return <SportsBikeNormalLarge />;
    case 'SportsOtherNormalXsmall':
      return <SportsOtherNormalXsmall />;
    case 'SportsOtherNormalSmall':
      return <SportsOtherNormalSmall />;
    case 'SportsOtherNormalMedium':
      return <SportsOtherNormalMedium />;
    case 'SportsOtherNormalLarge':
      return <SportsOtherNormalLarge />;
    case 'SportsMultiNormalXsmall':
      return <SportsMultiNormalXsmall />;
    case 'SportsMultiNormalSmall':
      return <SportsMultiNormalSmall />;
    case 'SportsMultiNormalMedium':
      return <SportsMultiNormalMedium />;
    case 'SportsMultiNormalLarge':
      return <SportsMultiNormalLarge />;
    case 'SportsEBikeRideNormalXsmall':
      return <SportsEBikeRideNormalXsmall />;
    case 'SportsEBikeRideNormalSmall':
      return <SportsEBikeRideNormalSmall />;
    case 'SportsEBikeRideNormalMedium':
      return <SportsEBikeRideNormalMedium />;
    case 'SportsEBikeRideNormalLarge':
      return <SportsEBikeRideNormalLarge />;
    case 'SportsStandUpPaddlingNormalXsmall':
      return <SportsStandUpPaddlingNormalXsmall />;
    case 'SportsStandUpPaddlingNormalSmall':
      return <SportsStandUpPaddlingNormalSmall />;
    case 'SportsStandUpPaddlingNormalMedium':
      return <SportsStandUpPaddlingNormalMedium />;
    case 'SportsStandUpPaddlingNormalLarge':
      return <SportsStandUpPaddlingNormalLarge />;
    case 'SportsHandcycleNormalXsmall':
      return <SportsHandcycleNormalXsmall />;
    case 'SportsHandcycleNormalSmall':
      return <SportsHandcycleNormalSmall />;
    case 'SportsHandcycleNormalMedium':
      return <SportsHandcycleNormalMedium />;
    case 'SportsHandcycleNormalLarge':
      return <SportsHandcycleNormalLarge />;
    case 'SportsHikeNormalXsmall':
      return <SportsHikeNormalXsmall />;
    case 'SportsHikeNormalSmall':
      return <SportsHikeNormalSmall />;
    case 'SportsHikeNormalMedium':
      return <SportsHikeNormalMedium />;
    case 'SportsHikeNormalLarge':
      return <SportsHikeNormalLarge />;
    case 'SportsIceSkateNormalXsmall':
      return <SportsIceSkateNormalXsmall />;
    case 'SportsIceSkateNormalSmall':
      return <SportsIceSkateNormalSmall />;
    case 'SportsIceSkateNormalMedium':
      return <SportsIceSkateNormalMedium />;
    case 'SportsIceSkateNormalLarge':
      return <SportsIceSkateNormalLarge />;
    case 'SportsInlineSkateNormalXsmall':
      return <SportsInlineSkateNormalXsmall />;
    case 'SportsInlineSkateNormalSmall':
      return <SportsInlineSkateNormalSmall />;
    case 'SportsInlineSkateNormalMedium':
      return <SportsInlineSkateNormalMedium />;
    case 'SportsInlineSkateNormalLarge':
      return <SportsInlineSkateNormalLarge />;
    case 'SportsKayakingNormalXsmall':
      return <SportsKayakingNormalXsmall />;
    case 'SportsKayakingNormalSmall':
      return <SportsKayakingNormalSmall />;
    case 'SportsKayakingNormalMedium':
      return <SportsKayakingNormalMedium />;
    case 'SportsKayakingNormalLarge':
      return <SportsKayakingNormalLarge />;
    case 'SportsKitesurfNormalXsmall':
      return <SportsKitesurfNormalXsmall />;
    case 'SportsKitesurfNormalSmall':
      return <SportsKitesurfNormalSmall />;
    case 'SportsKitesurfNormalMedium':
      return <SportsKitesurfNormalMedium />;
    case 'SportsKitesurfNormalLarge':
      return <SportsKitesurfNormalLarge />;
    case 'SportsRockClimbingNormalXsmall':
      return <SportsRockClimbingNormalXsmall />;
    case 'SportsRockClimbingNormalSmall':
      return <SportsRockClimbingNormalSmall />;
    case 'SportsRockClimbingNormalMedium':
      return <SportsRockClimbingNormalMedium />;
    case 'SportsRockClimbingNormalLarge':
      return <SportsRockClimbingNormalLarge />;
    case 'SportsRowingNormalXsmall':
      return <SportsRowingNormalXsmall />;
    case 'SportsRowingNormalSmall':
      return <SportsRowingNormalSmall />;
    case 'SportsRowingNormalMedium':
      return <SportsRowingNormalMedium />;
    case 'SportsRowingNormalLarge':
      return <SportsRowingNormalLarge />;
    case 'SportsSkiNormalXsmall':
      return <SportsSkiNormalXsmall />;
    case 'SportsSkiNormalSmall':
      return <SportsSkiNormalSmall />;
    case 'SportsSkiNormalMedium':
      return <SportsSkiNormalMedium />;
    case 'SportsSkiNormalLarge':
      return <SportsSkiNormalLarge />;
    case 'SportsSnowboardNormalXsmall':
      return <SportsSnowboardNormalXsmall />;
    case 'SportsSnowboardNormalSmall':
      return <SportsSnowboardNormalSmall />;
    case 'SportsSnowboardNormalMedium':
      return <SportsSnowboardNormalMedium />;
    case 'SportsSnowboardNormalLarge':
      return <SportsSnowboardNormalLarge />;
    case 'SportsSnowNormalXsmall':
      return <SportsSnowNormalXsmall />;
    case 'SportsSnowNormalSmall':
      return <SportsSnowNormalSmall />;
    case 'SportsSnowNormalMedium':
      return <SportsSnowNormalMedium />;
    case 'SportsSnowNormalLarge':
      return <SportsSnowNormalLarge />;
    case 'SportsSnowshoeNormalXsmall':
      return <SportsSnowshoeNormalXsmall />;
    case 'SportsSnowshoeNormalSmall':
      return <SportsSnowshoeNormalSmall />;
    case 'SportsSnowshoeNormalMedium':
      return <SportsSnowshoeNormalMedium />;
    case 'SportsSnowshoeNormalLarge':
      return <SportsSnowshoeNormalLarge />;
    case 'SportsSurfingNormalXsmall':
      return <SportsSurfingNormalXsmall />;
    case 'SportsSurfingNormalSmall':
      return <SportsSurfingNormalSmall />;
    case 'SportsSurfingNormalMedium':
      return <SportsSurfingNormalMedium />;
    case 'SportsSurfingNormalLarge':
      return <SportsSurfingNormalLarge />;
    case 'SportsTriathlonNormalXsmall':
      return <SportsTriathlonNormalXsmall />;
    case 'SportsTriathlonNormalSmall':
      return <SportsTriathlonNormalSmall />;
    case 'SportsTriathlonNormalMedium':
      return <SportsTriathlonNormalMedium />;
    case 'SportsTriathlonNormalLarge':
      return <SportsTriathlonNormalLarge />;
    case 'SportsVelomobileNormalXsmall':
      return <SportsVelomobileNormalXsmall />;
    case 'SportsVelomobileNormalSmall':
      return <SportsVelomobileNormalSmall />;
    case 'SportsVelomobileNormalMedium':
      return <SportsVelomobileNormalMedium />;
    case 'SportsVelomobileNormalLarge':
      return <SportsVelomobileNormalLarge />;
    case 'SportsWalkNormalXsmall':
      return <SportsWalkNormalXsmall />;
    case 'SportsWalkNormalSmall':
      return <SportsWalkNormalSmall />;
    case 'SportsWalkNormalMedium':
      return <SportsWalkNormalMedium />;
    case 'SportsWalkNormalLarge':
      return <SportsWalkNormalLarge />;
    case 'SportsWaterNormalXsmall':
      return <SportsWaterNormalXsmall />;
    case 'SportsWaterNormalSmall':
      return <SportsWaterNormalSmall />;
    case 'SportsWaterNormalMedium':
      return <SportsWaterNormalMedium />;
    case 'SportsWaterNormalLarge':
      return <SportsWaterNormalLarge />;
    case 'SportsWeightTrainingNormalXsmall':
      return <SportsWeightTrainingNormalXsmall />;
    case 'SportsWeightTrainingNormalSmall':
      return <SportsWeightTrainingNormalSmall />;
    case 'SportsWeightTrainingNormalMedium':
      return <SportsWeightTrainingNormalMedium />;
    case 'SportsWeightTrainingNormalLarge':
      return <SportsWeightTrainingNormalLarge />;
    case 'SportsWheelchairNormalXsmall':
      return <SportsWheelchairNormalXsmall />;
    case 'SportsWheelchairNormalSmall':
      return <SportsWheelchairNormalSmall />;
    case 'SportsWheelchairNormalMedium':
      return <SportsWheelchairNormalMedium />;
    case 'SportsWheelchairNormalLarge':
      return <SportsWheelchairNormalLarge />;
    case 'SportsWindsurfNormalXsmall':
      return <SportsWindsurfNormalXsmall />;
    case 'SportsWindsurfNormalSmall':
      return <SportsWindsurfNormalSmall />;
    case 'SportsWindsurfNormalMedium':
      return <SportsWindsurfNormalMedium />;
    case 'SportsWindsurfNormalLarge':
      return <SportsWindsurfNormalLarge />;
    case 'SportsYogaNormalXsmall':
      return <SportsYogaNormalXsmall />;
    case 'SportsYogaNormalSmall':
      return <SportsYogaNormalSmall />;
    case 'SportsYogaNormalMedium':
      return <SportsYogaNormalMedium />;
    case 'SportsYogaNormalLarge':
      return <SportsYogaNormalLarge />;
    case 'ActionsCancelNormalXsmall':
      return <ActionsCancelNormalXsmall />;
    case 'ActionsCancelNormalSmall':
      return <ActionsCancelNormalSmall />;
    case 'ActionsCancelNormalMedium':
      return <ActionsCancelNormalMedium />;
    case 'ActionsCancelNormalLarge':
      return <ActionsCancelNormalLarge />;
    case 'NavigationChallengeFastestActivityNormalSmall':
      return <NavigationChallengeFastestActivityNormalSmall />;
    case 'NavigationChallengeTotalActivityNormalSmall':
      return <NavigationChallengeTotalActivityNormalSmall />;
    case 'NavigationChallengeLongestActivityNormalSmall':
      return <NavigationChallengeLongestActivityNormalSmall />;
    case 'NavigationChallengeGroupGoalNormalSmall':
      return <NavigationChallengeGroupGoalNormalSmall />;
    case 'AchievementsBadgeNormalXsmall':
      return <AchievementsBadgeNormalXsmall />;
    case 'AchievementsBadgeNormalSmall':
      return <AchievementsBadgeNormalSmall />;
    case 'AchievementsBadgeNormalMedium':
      return <AchievementsBadgeNormalMedium />;
    case 'AchievementsBadgeNormalLarge':
      return <AchievementsBadgeNormalLarge />;
    default:
      return <></>;
  }
}
