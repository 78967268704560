/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';

import Day from '../Day';

import styles from './styles.scss';

const CalendarBody = ({ isCompletable, weeks }) => (
  <div className={styles.container}>
    {weeks.map((week, index) => (
      <ol key={`week-${index + 1}`} className={styles.week}>
        {week.map((day) => (
          <li key={day.date}>
            <Day {...day} isCompletable={isCompletable} />
          </li>
        ))}
      </ol>
    ))}
  </div>
);

CalendarBody.propTypes = {
  isCompletable: PropTypes.bool.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default CalendarBody;
