import React from 'react';
import PropTypes from 'prop-types';

import TextWithIcon from '../TextWithIcon';

import styles from './styles.scss';

const Summary = ({ summary }) => (
  <ul className={styles.container}>
    {Object.keys(summary).map(
      (key) =>
        summary[key].title && (
          <li key={key}>
            <TextWithIcon {...summary[key]} />
          </li>
        )
    )}
  </ul>
);

Summary.propTypes = {
  summary: PropTypes.shape({
    calendar: PropTypes.shape({}).isRequired,
    challenge: PropTypes.shape({}).isRequired,
    reward: PropTypes.shape({}).isRequired,
    segment: PropTypes.shape({})
  }).isRequired
};

export default Summary;
