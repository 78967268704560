// This file is deprecated and only used to move node_modules accessed in the
// sprockets asset pipeline to webpack entry points
// adding random string to force recompile:
// A998D3152224818D225A880A9B627F1FB4484DD7E408EACA1B243326ED4AA01B

// importing jquery using script-loader to mimic adding jQuery via a script tag
/* eslint-disable no-unused-vars,import/extensions */
import 'jquery/dist/jquery.min.js';
import 'leaflet/dist/leaflet.js';
import 'dexie/dist/dexie.min.js';
import 'intersection-observer/intersection-observer.js';
import '@strava/autotrack/autotrack.js';
import 'dropzone/dist/dropzone.js';
import 'blueimp-load-image/js/load-image.all.min.js';
import 'dropzone/dist/min/dropzone.min.css';

import { setPublicServiceEnv } from './utils/public-service-env';
import { devLog, isDev } from './utils/devUtils';

import { logError } from './utils/sentry';

// Expose method on global scope that sets public keys that are commonly passed
// to 3rd party libs or tooling.
if (window.stravaPublicServiceEnv === undefined) {
  window.stravaPublicServiceEnv = setPublicServiceEnv;
}

// check if global libraries are being loaded multiple times.
// Loading the libs might cause issues like https://reactjs.org/docs/error-decoder.html/?invariant=321
// since react is loaded twice etc
if (window.globalLibsLoaded === undefined) {
  window.globalLibsLoaded = true;
} else if (isDev()) {
  // eslint-disable-next-line no-alert
  alert(
    'WARNING: global libraries are loaded twice on this page. This could cause react errors.'
  );
} else {
  logError(
    new Error(`global libraries are loaded twice on - ${window.location.href}`)
  );
}

devLog('loading global libraries');
