// Object containing no secret key value pairs that are expected to be seen and
// used in the browser. This keys are commonly passed to 3rd party libs or tooling.
//
// Data that gets set to this object is set in the following file:
// app/views/common/_react_public_service_env.haml
//
// Do not set values directly into this object here as that is not intended use
const publicServiceEnv = {};

/**
 * Sets data to an object and freezes it on the initial call.
 * @param {Object} envData Object containing key value pairs that are not nested objects
 */
function setPublicServiceEnv(envData) {
  // Only update the object if it's empty as we do not want to allow any updated
  // after the initial call to this method
  if (Object.keys(publicServiceEnv).length === 0) {
    const keys = Object.keys(envData);

    if (keys.length > 0) {
      keys.forEach((value) => {
        publicServiceEnv[value] = envData[value];
      });

      // Freeze object so that it can not be updated or changed
      Object.freeze(publicServiceEnv);
    }
  }
}

export { publicServiceEnv, setPublicServiceEnv };
