import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.scss';

export const createMilestoneMarkers = (numMilestones) => {
  const percentInterval = 100 / numMilestones;
  const milestoneMarkers = [];
  for (let i = 1; i < numMilestones; i += 1) {
    milestoneMarkers.push(
      <div
        key={`${i * percentInterval}%`}
        style={{ left: `${i * percentInterval}%` }}
        className={styles.milestoneMarker}
        data-testid="milestoneMarkers"
      />
    );
  }

  return milestoneMarkers;
};

const ProgressBar = ({
  rightLabel,
  leftLabel,
  leftLabelExtension,
  milestones,
  progressBarFraction,
  className,
  title
}) => (
  <div className={className || styles.container} data-testid="progress-bar">
    {title && <h2 className={`text-headline ${styles.title}`}>{title}</h2>}
    {leftLabel && (
      <div className={styles.labels}>
        <div>
          <span
            className={`text-body ${styles.labelLeft} ${
              progressBarFraction > 0 ? styles.primary : ''
            }`}
          >{`${leftLabel} `}</span>
          {leftLabelExtension && (
            <span className={`text-body ${styles.labelLeft}`}>
              {leftLabelExtension}
            </span>
          )}
        </div>
        <div
          className={`text-body ${styles.labelRight} ${
            progressBarFraction >= 1 ? styles.primary : ''
          }`}
        >
          {rightLabel}
        </div>
      </div>
    )}
    <div className={styles.progressContainer}>
      <div
        className={styles.progress}
        style={{ width: `${progressBarFraction * 100}%` }}
        data-testid="progressbar"
      />
      {milestones > 0 && createMilestoneMarkers(milestones)}
    </div>
  </div>
);

ProgressBar.propTypes = {
  rightLabel: PropTypes.string,
  leftLabel: PropTypes.string,
  leftLabelExtension: PropTypes.string,
  milestones: PropTypes.number,
  progressBarFraction: PropTypes.number.isRequired,
  className: PropTypes.string,
  title: PropTypes.string
};

export default ProgressBar;
