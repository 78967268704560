import React from 'react';
import PropTypes from 'prop-types';
import MediaQuery, { breakpoints } from 'utils/media-query';
import I18n from 'utils/I18n';
import Cldr from 'utils/Cldr';

import ProgressBar from './ProgressBar';
import CalendarBody from './CalendarBody';

import styles from './styles.scss';

const weekI18n = 'templates.generic.week_uppercase';

const dayKeys = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

export const dayString = (day, short) =>
  Cldr?.calendar?.weekdays({
    format: 'stand-alone',
    names_form: short ? 'narrow' : 'abbreviated'
  })[day];

const StreaksCalendar = ({
  isCompletable,
  currentProgress,
  totalWeeks,
  weeks
}) => (
  <div className={styles.container} data-testid="streaks-calendar">
    <MediaQuery minWidth={breakpoints.screenXs}>
      <div className={`text-caption2 ${styles.headerLeft}`}>
        {I18n.t(weekI18n)}
      </div>
      <ol className={styles.headerRight}>
        {dayKeys.map((day) => (
          <li key={day} className={`text-subhead ${styles.label}`}>
            {dayString(day, false)}
          </li>
        ))}
      </ol>
    </MediaQuery>

    <MediaQuery maxWidth={breakpoints.screenXs}>
      <div className={`text-caption3 ${styles.headerLeft}`}>
        {I18n.t(weekI18n)}
      </div>
      <ol className={styles.headerRight}>
        {dayKeys.map((day) => (
          <li key={day} className={`text-caption1 ${styles.label}`}>
            {dayString(day, true)}
          </li>
        ))}
      </ol>
    </MediaQuery>

    <div className={styles.progressBar}>
      <ProgressBar
        isCompletable={isCompletable}
        currentProgress={currentProgress}
        totalWeeks={totalWeeks}
      />
    </div>
    <div className={styles.calendarBody}>
      <CalendarBody isCompletable={isCompletable} weeks={weeks} />
    </div>
  </div>
);

StreaksCalendar.propTypes = {
  isCompletable: PropTypes.bool.isRequired,
  currentProgress: PropTypes.number.isRequired,
  totalWeeks: PropTypes.number.isRequired,
  weeks: PropTypes.arrayOf(PropTypes.array).isRequired
};

export default StreaksCalendar;
