import React from 'react';
import PropTypes from 'prop-types';

function lazy(name) {
  return React.lazy(() => import(`@strava/icons/${name}`));
}

const activities = {
  alpineski: {
    small: lazy('SportsSkiNormalSmall'),
    xsmall: lazy('SportsSkiNormalXsmall')
  },
  backcountryski: {
    small: lazy('SportsSkiNormalSmall'),
    xsmall: lazy('SportsSkiNormalXsmall')
  },
  badminton: {
    small: lazy('SportsBadmintonNormalSmall'),
    xsmall: lazy('SportsBadmintonNormalXsmall')
  },
  canoeing: {
    small: lazy('SportsKayakingNormalSmall'),
    xsmall: lazy('SportsKayakingNormalXsmall')
  },
  crossfit: {
    small: lazy('SportsWeightTrainingNormalSmall'),
    xsmall: lazy('SportsWeightTrainingNormalXsmall')
  },
  ebikeride: {
    small: lazy('SportsEBikeRideNormalSmall'),
    xsmall: lazy('SportsEBikeRideNormalXsmall')
  },
  emountainbikeride: {
    small: lazy('SportsBikeEMountainNormalSmall'),
    xsmall: lazy('SportsBikeEMountainNormalXsmall')
  },
  elliptical: {
    small: lazy('SportsOtherNormalSmall'),
    xsmall: lazy('SportsOtherNormalXsmall')
  },
  golf: {
    small: lazy('SportsGolfNormalSmall'),
    xsmall: lazy('SportsGolfNormalXsmall')
  },
  gravelride: {
    small: lazy('SportsBikeGravelNormalSmall'),
    xsmall: lazy('SportsBikeGravelNormalXsmall')
  },
  highintensityintervaltraining: {
    small: lazy('SportsHiitNormalSmall'),
    xsmall: lazy('SportsHiitNormalXSmall')
  },
  handcycle: {
    small: lazy('SportsHandcycleNormalSmall'),
    xsmall: lazy('SportsHandcycleNormalXsmall')
  },
  hike: {
    small: lazy('SportsHikeNormalSmall'),
    xsmall: lazy('SportsHikeNormalXsmall')
  },
  iceskate: {
    small: lazy('SportsIceSkateNormalSmall'),
    xsmall: lazy('SportsIceSkateNormalXsmall')
  },
  inlineskate: {
    small: lazy('SportsInlineSkateNormalSmall'),
    xsmall: lazy('SportsInlineSkateNormalXsmall')
  },
  kayaking: {
    small: lazy('SportsKayakingNormalSmall'),
    xsmall: lazy('SportsKayakingNormalXsmall')
  },
  kitesurf: {
    small: lazy('SportsKitesurfNormalSmall'),
    xsmall: lazy('SportsKitesurfNormalXsmall')
  },
  mountainbikeride: {
    small: lazy('SportsBikeMountainNormalSmall'),
    xsmall: lazy('SportsBikeMountainNormalXsmall')
  },
  nordicski: {
    small: lazy('SportsSkiNormalSmall'),
    xsmall: lazy('SportsSkiNormalXsmall')
  },
  pickleball: {
    small: lazy('SportsPickleballNormalSmall'),
    xsmall: lazy('SportsPickleballNormalXSmall')
  },
  pilates: {
    small: lazy('SportsPilatesNormalSmall'),
    xsmall: lazy('SportsPilatesNormalXSmall')
  },
  racquetball: {
    small: lazy('SportsRacquetballNormalSmall'),
    xsmall: lazy('SportsRacquetballNormalXSmall')
  },
  ride: {
    small: lazy('SportsBikeNormalSmall'),
    xsmall: lazy('SportsBikeNormalXsmall')
  },
  rockclimbing: {
    small: lazy('SportsRockClimbingNormalSmall'),
    xsmall: lazy('SportsRockClimbingNormalXsmall')
  },
  rollerski: {
    small: lazy('SportsSkiNormalSmall'),
    xsmall: lazy('SportsSkiNormalXsmall')
  },
  rowing: {
    small: lazy('SportsRowingNormalSmall'),
    xsmall: lazy('SportsRowingNormalXsmall')
  },
  run: {
    small: lazy('SportsRunNormalSmall'),
    xsmall: lazy('SportsRunNormalXsmall')
  },
  sail: {
    small: lazy('SportsWindsurfNormalSmall'),
    xsmall: lazy('SportsWindsurfNormalXsmall')
  },
  skateboard: {
    small: lazy('SportsSkateboardNormalSmall'),
    xsmall: lazy('SportsSkateboardNormalXsmall')
  },
  snowboard: {
    small: lazy('SportsSnowboardNormalSmall'),
    xsmall: lazy('SportsSnowboardNormalXsmall')
  },
  snowshoe: {
    small: lazy('SportsSnowshoeNormalSmall'),
    xsmall: lazy('SportsSnowshoeNormalXsmall')
  },
  soccer: {
    small: lazy('SportsSoccerNormalSmall'),
    xsmall: lazy('SportsSoccerNormalXsmall')
  },
  squash: {
    small: lazy('SportsSquashNormalSmall'),
    xsmall: lazy('SportsSquashNormalXsmall')
  },
  stairstepper: {
    small: lazy('SportsEquipmentStepNormalSmall'),
    xsmall: lazy('SportsEquipmentStepNormalXsmall')
  },
  standuppaddling: {
    small: lazy('SportsStandUpPaddlingNormalSmall'),
    xsmall: lazy('SportsStandUpPaddlingNormalXsmall')
  },
  surfing: {
    small: lazy('SportsSurfingNormalSmall'),
    xsmall: lazy('SportsSurfingNormalXsmall')
  },
  swim: {
    small: lazy('SportsWaterNormalSmall'),
    xsmall: lazy('SportsWaterNormalXsmall')
  },
  tabletennis: {
    small: lazy('SportsTableTennisNormalSmall'),
    xsmall: lazy('SportsTableTennisNormalXSmall')
  },
  tennis: {
    small: lazy('SportsTennisNormalSmall'),
    xsmall: lazy('SportsTennisNormalXSmall')
  },
  trailrun: {
    small: lazy('SportsRunTrailNormalSmall'),
    xsmall: lazy('SportsRunTrailNormalXsmall')
  },
  velomobile: {
    small: lazy('SportsVelomobileNormalSmall'),
    xsmall: lazy('SportsVelomobileNormalXsmall')
  },
  virtualride: {
    small: lazy('SportsBikeNormalSmall'),
    xsmall: lazy('SportsBikeNormalXsmall')
  },
  virtualrow: {
    small: lazy('SportsVirtualRowNormalSmall'),
    xsmall: lazy('SportsVirtualRowNormalXSmall')
  },
  virtualrun: {
    small: lazy('SportsRunNormalSmall'),
    xsmall: lazy('SportsRunNormalXsmall')
  },
  walk: {
    small: lazy('SportsWalkNormalSmall'),
    xsmall: lazy('SportsWalkNormalXsmall')
  },
  watersport: {
    small: lazy('SportsWaterNormalSmall'),
    xsmall: lazy('SportsWaterNormalXsmall')
  },
  weighttraining: {
    small: lazy('SportsWeightTrainingNormalSmall'),
    xsmall: lazy('SportsWeightTrainingNormalXsmall')
  },
  wheelchair: {
    small: lazy('SportsWheelchairNormalSmall'),
    xsmall: lazy('SportsWheelchairNormalXsmall')
  },
  windsurf: {
    small: lazy('SportsWindsurfNormalSmall'),
    xsmall: lazy('SportsWindsurfNormalXsmall')
  },
  wintersport: {
    small: lazy('SportsSnowNormalSmall'),
    xsmall: lazy('SportsSnowNormalXsmall')
  },
  workout: {
    small: lazy('SportsOtherNormalSmall'),
    xsmall: lazy('SportsOtherNormalXsmall')
  },
  yoga: {
    small: lazy('SportsYogaNormalSmall'),
    xsmall: lazy('SportsYogaNormalXsmall')
  }
};

const ActivityIcon = ({ type, size, ...props }) => {
  const sportTypeIcons = activities[type.toLowerCase()] || activities.workout;
  const SportTypeIcon = sportTypeIcons[size.toLowerCase()];

  if (SportTypeIcon) {
    return (
      <React.Suspense fallback={<div />}>
        {React.createElement(SportTypeIcon, { ...props })}
      </React.Suspense>
    );
  }

  return null;
};

ActivityIcon.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['xsmall', 'Xsmall', 'small', 'Small']).isRequired
};

export default ActivityIcon;
